var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"uk-text-large my-4"},[_vm._v(" "+_vm._s(_vm.variations[0].type.name)+" disponibles ")]),_c('div',{staticClass:"glide uk-position-relative",attrs:{"id":_vm.glideId}},[_c('div',{staticClass:"glide__track mb-6",staticStyle:{"padding-left":"50px","padding-right":"50px"},attrs:{"data-glide-el":"track"}},[_c('ul',{staticClass:"glide__slides"},_vm._l((_vm.displayVariations),function(variation,index){return _c('li',{key:variation.id,staticClass:"glide__slide",attrs:{"title":variation.value}},[_c('button',{staticClass:"uk-button uk-border-rounded variation-card uk-width-1-1",class:{
              'uk-button-primary':
                _vm.activeVariationId && _vm.activeVariationId === variation.id,
            },on:{"click":function($event){return _vm.setInternalVariation(variation, index)}}},[_c('div',{staticClass:"uk-position-relative"},[_c('div',[_c('span',{staticStyle:{"display":"block","height":"100%","vertical-align":"middle"}}),_c('img',{staticClass:"uk-border-rounded",staticStyle:{"vertical-align":"middle"},attrs:{"src":variation.displayImage ? variation.displayImage.url : '',"width":"100%","alt":""}})]),(
                  variation.provider_code &&
                  (_vm.storefrontStorefront.type === 'b2b'
                    ? !(variation.id in _vm.quotableIds) && variation.stock > 0
                    : variation.storefront_redeemables.length &&
                      variation.storefront_redeemables[
                        variation.storefront_redeemables.length - 1
                      ].current_stock > 0 &&
                      !(
                        variation.storefront_redeemables[
                          variation.storefront_redeemables.length - 1
                        ].id in _vm.quotableIds
                      ))
                )?_c('button',{staticClass:"uk-position-absolute uk-position-top-right uk-button-primary uk-border-rounded",staticStyle:{"padding":"3px"},attrs:{"test":"add-variation-button","uk-icon":"plus"},on:{"click":function($event){$event.stopPropagation();return _vm.addQuotable(
                    _vm.storefrontStorefront.type === 'b2b'
                      ? variation.id
                      : variation.storefront_redeemables[
                          variation.storefront_redeemables.length - 1
                        ].id
                  )}}}):_vm._e(),(
                  variation.provider_code &&
                  (_vm.storefrontStorefront.type === 'b2b'
                    ? variation.id in _vm.quotableIds
                    : variation.storefront_redeemables.length &&
                      variation.storefront_redeemables[
                        variation.storefront_redeemables.length - 1
                      ].id in _vm.quotableIds)
                )?_c('button',{staticClass:"uk-position-absolute uk-position-top-right uk-button-primary uk-border-rounded",staticStyle:{"padding":"3px"},attrs:{"uk-icon":"check"},on:{"click":function($event){$event.stopPropagation();return _vm.removeQuotable(
                    _vm.storefrontStorefront.type === 'b2b'
                      ? variation.id
                      : variation.storefront_redeemables[
                          variation.storefront_redeemables.length - 1
                        ].id
                  )}}}):_vm._e()]),(
                _vm.activeVariationId &&
                _vm.activeVariationId === variation.id &&
                variation.value.length > 6
              )?_c('marquee-text',{attrs:{"duration":3,"repeat":3}},[_vm._v(" "+_vm._s(variation.value)+" "),_c('span',{staticClass:"uk-margin-right uk-margin-left"})]):_c('div',{staticClass:"uk-text-truncate"},[_vm._v(_vm._s(variation.value))]),(_vm.canSeeStock)?_c('div',[(_vm.storefrontStorefront.type === 'b2b')?_c('div',[(variation.stock)?_c('div',[_c('div',{staticClass:"uk-flex uk-flex-center uk-flex-middle"},[_c('div',{staticClass:"uk-text-small"},[_c('div',[_vm._v("Stock")]),_c('div',[(variation.stock <= 100000)?_c('span',[_vm._v(_vm._s(_vm.formatThousands(variation.stock)))]):_c('span',[_vm._v(" Ilimitado ")])])]),(_vm.updatingProviderInfo)?_c('div',{staticClass:"uk-margin-small-left",attrs:{"uk-spinner":""}}):_vm._e()])]):(variation.children.length === 0)?_c('div',{staticClass:"uk-text-small"},[_c('div',[_vm._v("No")]),_c('div',[_vm._v("disponible")])]):_vm._e()]):_vm._e(),(
                  _vm.storefrontStorefront.type === 'redeem' &&
                  variation.storefront_redeemables.length
                )?_c('div',[_c('div',{staticClass:"uk-flex uk-flex-center uk-flex-middle"},[_c('div',{staticClass:"uk-text-small"},[_vm._v(" Stock: "+_vm._s(_vm.formatThousands( variation.storefront_redeemables[ variation.storefront_redeemables.length - 1 ].current_stock ))+" ")])])]):_vm._e()]):_vm._e()],1)])}),0)]),_c('div',{staticClass:"uk-flex uk-flex-between glide__arrows",attrs:{"data-glide-el":"controls"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"mt-2 slider__bullets glide__bullets uk- uk-flex-center",attrs:{"data-glide-el":"controls[nav]"}},_vm._l((_vm.displayVariations),function(variation,index){return _c('button',{key:'dotnav-' + variation.id,staticClass:"mt-4 slider__bullet glide__bullet glide__bullet uk-dotnav",attrs:{"data-glide-dir":'=' + index},on:{"click":function($event){return _vm.setInternalVariation(variation, index)}}})}),0)])]),(_vm.activeVariation && _vm.activeVariation.children.length !== 0)?_c('VariationSelector',{attrs:{"variations":_vm.activeVariation.children,"setVariation":_vm.setVariation,"updatingProviderInfo":_vm.updatingProviderInfo,"canSeeStock":_vm.canSeeStock,"canSeePriceUpdates":_vm.canSeePriceUpdates,"quotableIds":_vm.quotableIds}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"uk-link-reset uk-position-center-left uk-flex uk-items-center",staticStyle:{"background":"white","height":"105%"},attrs:{"data-glide-dir":"<"}},[_c('div',{staticClass:"uk-flex",attrs:{"uk-icon":"icon: chevron-left; ratio: 2;"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"uk-link-reset uk-position-center-right uk-flex uk-items-center",staticStyle:{"background":"white","height":"105%"},attrs:{"data-glide-dir":">"}},[_c('div',{staticClass:"uk-flex",attrs:{"uk-icon":"icon: chevron-right; ratio: 2;"}})])}]

export { render, staticRenderFns }